<template>
    <b-modal id="modal" :title="data.name_cs" no-close-on-backdrop
             no-close-on-esc
             size="lg"
             @close="onReset()"
    >
        <b-form v-if="data.id" id="form1" autocomplete="off" @reset="onReset" @submit.prevent="onSubmit">
            
            
            <b-row v-show="data.phase == 1">
                
                <div class="col-12">
                    <h3>1. Obecné údaje o projektu | Ogólne dane o projekcie</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Název projektu</label>
                            <b-form-input v-model="data.name_cs"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>Tytuł projektu</label>
                            <b-form-input v-model="data.name_pl"></b-form-input>
                        </div>
                    </div>
                </div>
                
                <hr class="w-100">
                
                <div class="col-12">
                    <label>Doba realizace projektu | Okres realizacji projektu<br>
                        <small>(Od prvního právního úkonu, nejdříve 17.10.2022.) | (Od pierwszej czynności prawnej,
                            najwcześniej 17.10.2022 r.)</small>
                    </label>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Začátek | Rozpoczęcie </label>
                            <b-form-datepicker v-model="data.date_start.date" class="mb-2"></b-form-datepicker>
                        </div>
                        <div class="col-6 form-group">
                            <label>Konec | Zakończenie </label>
                            <b-form-datepicker v-model="data.date_end.date" class="mb-2"></b-form-datepicker>
                        </div>
                        <div class="col-12 form-group">
                            <label>Číslo výzvy | Numer naboru:</label>
                            <b-form-input v-model="data.number_of_call" readonly></b-form-input>
                        </div>
                    </div>
                
                </div>
                
                <hr class="w-100">
                
                <div class="col-12">
                    <h3>2. Údaje o žadateli | Dane wnioskodawcy</h3>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Název (organizace) | Nazwa (instytucja) </label>
                            <b-form-input v-model="data.user_name"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>Právní forma | Forma prawna </label>
                            <b-form-input v-model="data.user_legal_form"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>Identifikační číslo | NIP</label>
                            <b-form-input v-model="data.user_id"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>Adresa | Adres</label>
                            <b-form-input v-model="data.user_address"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                Statutární zástupce | Osoba uprawniona do zaciągania zobowiązań (przedstawiciel
                                statutowy)<br>
                                <small>(jméno, telefon, email) | (imię i nazwisko, tel., e-mail)</small>
                            </label>
                            <b-textarea v-model="data.statutory_representative"></b-textarea>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                Kontaktní osoba | Osoba do kontaktu<br>
                                <small>(jméno, telefon, email) | (imię i nazwisko, tel., e-mail)</small>
                            </label>
                            <b-textarea v-model="data.contact_person"></b-textarea>
                        </div>
                    </div>
                </div>
                
                <hr class="w-100">
                
                <div class="col-12">
                    <h3>3. Údaje k projektu | Dane o projekcie</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Popis projektu&nbsp;<small id="popover-1-3-1-cs">(pokyny k vyplnění&nbsp;<b-icon
                                aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-1-3-1-cs" triggers="hover focus">
                                <p>V čem projekt spočívá, jaké činnosti budou realizovány, technické údaje, např. délka
                                    vybudované/modernizované sítě infrastruktury, počet obyvatel využívajících
                                    infrastrukturu atd.</p>
                                <p>Cíl projektu</p>
                                <p>Příspěvek projektu k řešení problémů společného území</p>
                                <p>Popis problémů, které chce projekt řešit</p>
                                <p>Popis potenciálů, které chce projekt rozvíjet</p>
                            
                            </b-popover>
                            <b-textarea v-model="data.project_description_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Opis projektu&nbsp;<small id="popover-1-3-1-pl">(instrukcja wypełniania&nbsp;<b-icon
                                aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-1-3-1-pl" triggers="hover focus">
                                <p>Na czym polega projekt, jakie działania będą realizowane, dane techniczne, np.
                                    długość zbudowanej / zmodernizowanej sieci infrastruktury, liczba mieszkańców
                                    korzystających z infrastruktury, itd.</p>
                                <p>Cel projektu</p>
                                <p>Wkład projektu w rozwiązywanie problemów wspólnego obszaru</p>
                                <p>Opis problemów, które projekt chce rozwiązać</p>
                                <p>Opis potencjałów, które projekt chce rozwijać</p>
                            
                            </b-popover>
                            <b-textarea v-model="data.project_description_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Popis stavu připravenosti projektu k realizaci&nbsp;<small
                                id="popover-1-3-2-cs">(pokyny k vyplnění&nbsp;<b-icon aria-hidden="true"
                                                                                      icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-1-3-2-cs" triggers="hover focus">
                                Vlastněná dokumentace, smlouvy, vydané souhlasy atd. - s odkazem na všechny právní
                                požadavky platné pro daný typ projektu – týká se i environmentálních požadavků atd.
                            </b-popover>
                            <b-textarea v-model="data.status_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label> Opis stanu przygotowania projektu do realizacji&nbsp;<small
                                id="popover-1-3-2-pl">(instrukcja wypełniania&nbsp;<b-icon aria-hidden="true"
                                                                                           icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-1-3-2-pl" triggers="hover focus">
                                Posiadana dokumentacja, umowy, wydane zgody, itd. – z odwołaniem do wszystkich
                                obowiązujących wymogów prawa dla danego typu przedsięwzięcia – dotyczy także wymogów
                                środowiskowych, itp.
                            </b-popover>
                            <b-textarea v-model="data.status_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Výsledky, přidaná hodnota a udržitelnost projektu</label>
                            <b-textarea v-model="data.results_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Rezultaty, wartość dodana i trwałość projektu</label>
                            <b-textarea v-model="data.results_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Popis předpokládaného vlivu na životní prostředí</label>
                            <b-textarea v-model="data.enviroment_impact_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Opis zakładanego efektu środowiskowego</label>
                            <b-textarea v-model="data.enviroment_impact_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Popis dopadu projektu na druhou stranu hranice</label>
                            <b-textarea v-model="data.cross_border_impact_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Opis oddziaływania projektu na drugą stronę granicy</label>
                            <b-textarea v-model="data.cross_border_impact_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                    </div>
                </div>
            
            </b-row>
            
            <b-row v-show="data.phase == 2">
                <div class="col-12">
                    <h3>1. Údaje o žadateli / údaje o projektu | Dane wnioskodawcy / ogólne dane o projekcie</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Název projektu</label>
                            <b-form-input v-model="data.name_cs"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>Tytuł projektu</label>
                            <b-form-input v-model="data.name_pl"></b-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Název příjemce | Nazwa beneficjenta</label>
                            <b-form-input v-model="data.user_name"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>Identifikační číslo | NIP</label>
                            <b-form-input v-model="data.user_id"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>Adresa | Adres</label>
                            <b-form-input v-model="data.user_address"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                Statutární zástupce | Osoba uprawniona do zaciągania zobowiązań (przedstawiciel
                                statutowy)<br>
                                <small>(jméno, telefon, email) | (imię i nazwisko, tel., e-mail)</small>
                            </label>
                            <b-textarea v-model="data.statutory_representative"></b-textarea>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                Kontaktní osoba pro zpracování Závěrečné zprávy o realizaci projektu |<br> Osoba do
                                kontaktu w sprawie opracowania Raportu końcowego z realizacji projektu<br>
                                <small>(jméno, telefon, email) | (imię i nazwisko, tel., e-mail)</small>
                            </label>
                            <b-textarea v-model="data.contact_person"></b-textarea>
                        </div>
                    </div>
                </div>
                
                <hr class="w-100">
                
                <div class="col-12">
                    <label>Doba realizace projektu | Okres realizacji projektu</label>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Začátek | Początek </label>
                            <b-form-datepicker v-model="data.date_start.date" class="mb-2"></b-form-datepicker>
                        </div>
                        <div class="col-6 form-group">
                            <label>Konec | Koniec </label>
                            <b-form-datepicker v-model="data.date_end.date" class="mb-2"></b-form-datepicker>
                        </div>
                    </div>
                
                </div>
                
                <hr class="w-100">
                
                
                <div class="col-12">
                    <h3>2. Údaje o projektu | Dane o projekcie </h3>
                    <div class="row">
                        
                        <div class="col-6 form-group">
                            <label>Podrobný popis realizace projektu a změn v projektu&nbsp;<small
                                id="popover-3-1-cs">(pokyny k vyplnění&nbsp;<b-icon aria-hidden="true"
                                                                                    icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-1-cs" triggers="hover focus">
                                <p>Časový postup realizace (zahájení prací, ukončení prací, případné období přerušení
                                    prací, …)</p>
                                <p>Popis postupu prací</p>
                                <p>Popis realizovaných činností</p>
                                <p>Popis všech změn v projektu a způsob jejich řešení a oznámení správci programu FMP
                                    Turów</p>
                                <p>Jak projekt přispěl k řešení problémů společného území definovaných v žádosti o
                                    podporu?</p>
                                <p>Jak projekt přispěl k řešení problémů definovaných v žádosti o podporu?</p>
                                <p>Jak projekt rozvíjel potenciály definované v žádosti o podporu?</p>
                            
                            
                            </b-popover>
                            <b-textarea v-model="data.project_description_final_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Szczegółowy opis realizacji projektu i zmian w projekcie&nbsp;<small
                                id="popover-3-1-pl">(instrukcja wypełniania&nbsp;<b-icon aria-hidden="true"
                                                                                         icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-1-pl" triggers="hover focus">
                                <p>Realizacja projektu w czasie (rozpoczęcie prac, zakończenie prac, ew. okres
                                    wstrzymania prac, etc..)</p>
                                <p>Opis postępu prac</p>
                                <p>Opis realizowanych działań</p>
                                <p>Opis wszelkich zmian w projekcie oraz sposób ich procedowania i powiadamiania o nich
                                    Zarządzającego FMP Turów.</p>
                                <p>W jaki sposób projekt przyczynił się do rozwiązania problemów wspólnego obszaru
                                    zdefiniowanych we wniosku o dofinansowanie?</p>
                                <p>W jaki sposób projekt przyczynił się do rozwiązania problemów zdefiniowanych we
                                    wniosku o dofinansowanie?</p>
                                <p>W jaki sposób projekt rozwijał potencjały zdefiniowane we wniosku o
                                    dofinansowanie?</p>
                            
                            </b-popover>
                            <b-textarea v-model="data.project_description_final_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>Výsledky, přidaná hodnota a udržitelnost projektu&nbsp;<small
                                id="popover-3-2-cs">(pokyny k vyplnění&nbsp;<b-icon aria-hidden="true"
                                                                                    icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-2-cs" triggers="hover focus">
                                <p>Popis dosažených výsledků projektu</p>
                                <p>Popis přidané hodnoty projektu po skončení realizace</p>
                                <p>Popis využití výstupů projektu</p>
                                <p>Popis zajištění udržitelnosti projektu z hlediska institucionálního a finančního
                                    zajištění</p>
                            </b-popover>
                            <b-textarea v-model="data.results_final_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Rezultaty, wartość dodana i trwałość projektu&nbsp;<small
                                id="popover-3-2-pl">(instrukcja wypełniania&nbsp;<b-icon aria-hidden="true"
                                                                                         icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-2-pl" triggers="hover focus">
                                <p>Opis osiągniętych rezultatów projektu</p>
                                <p>Opis wartości dodanej projektu po zakończeniu realizacji</p>
                                <p>Opis wykorzystania produktów projektu</p>
                                <p>Opis zapewnienia trwałości projektu pod względem instytucjonalnym i finansowym</p>
                            </b-popover>
                            <b-textarea v-model="data.results_final_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>Popis předpokládaného vlivu na životní prostředí&nbsp;<small
                                id="popover-3-3-cs">(pokyny k vyplnění&nbsp;<b-icon aria-hidden="true"
                                                                                    icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-3-cs" triggers="hover focus">
                                <p>Popis stávajícího vlivu na životní prostředí v době bezprostředně po realizaci
                                    projektu</p>
                                <p>Popis předpokládaného vlivu na životní prostředí v dlouhodobém horizontu v době
                                    udržitelnosti</p>
                            </b-popover>
                            <b-textarea v-model="data.enviroment_impact_final_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Opis przewidywanego oddziaływania na środowisko&nbsp;<small
                                id="popover-3-3-pl">(instrukcja wypełniania&nbsp;<b-icon aria-hidden="true"
                                                                                         icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-3-pl" triggers="hover focus">
                                <p>Opis oddziaływania projektu na środowisko w okresie bezpośrednio po jego
                                    realizacji</p>
                                <p>Opis przewidywanego oddziaływania na środowisko w perspektywie długoterminowej w
                                    okresie trwałości projektu</p>
                            
                            </b-popover>
                            <b-textarea v-model="data.enviroment_impact_final_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>Popis dopadu projektu na druhou stranu hranice&nbsp;<small
                                id="popover-3-4-cs">(pokyny k vyplnění&nbsp;<b-icon aria-hidden="true"
                                                                                    icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-4-cs" triggers="hover focus">
                                <p>Popis dopadu projektu na druhou stranu hranice v době udržitelnosti projektu</p>
                            </b-popover>
                            <b-textarea v-model="data.cross_border_impact_final_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Opis wpływu projektu na drugą stronę granicy&nbsp;<small id="popover-3-4-pl">(instrukcja
                                wypełniania&nbsp;<b-icon aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-4-pl" triggers="hover focus">
                                <p>Opis wpływu projektu na drugą stronę granicy w okresie trwałości projektu</p>
                            </b-popover>
                            <b-textarea v-model="data.cross_border_impact_final_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>Informační a propagační činnosti</label>&nbsp;<small id="popover-3-5-cs">(pokyny k
                            vyplnění&nbsp;<b-icon aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small>
                            <b-popover target="popover-3-5-cs" triggers="hover focus">
                                <p>Popis provedení povinné propagační činnosti – propagace na internetu, umístění
                                    pamětní desky</p>
                                <p>Popis provedení případné další nepovinné propagační činnosti</p>
                            </b-popover>
                            <b-textarea v-model="data.publishing_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Działania informacyjno-promocyjne&nbsp;<small id="popover-3-5-pl">(instrukcja
                                wypełniania&nbsp;<b-icon aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-5-pl" triggers="hover focus">
                                <p>Opis realizacji obowiązkowych działań promocyjnych - promocja w Internecie,
                                    umieszczenie tablicy pamiątkowej</p>
                                <p>Opis realizacji wszelkich innych fakultatywnych działań promocyjnych</p>
                            </b-popover>
                            <b-textarea v-model="data.publishing_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>Splnění milníků projektu definovaných ve Smlouvě o financování z Fondu malých
                                projektů Turów&nbsp;<small id="popover-3-6-cs">(pokyny k vyplnění&nbsp;<b-icon
                                    aria-hidden="true"
                                    icon="info-circle"></b-icon>&nbsp;)</small>
                            </label>
                            <b-popover target="popover-3-6-cs" triggers="hover focus">
                                <p>Povinná struktura uváděných informací:</p>
                                <ul>
                                    <li>Název milníku</li>
                                    <li>Popis dosaženého plánovaného milníku</li>
                                    <li>Dosažené měřitelné ukazatele milníku</li>
                                    <li>Seznam dokumentů potvrzujících, že plánovaný milník byl dosažen (tyto dokumenty
                                        musí být přiloženy k této zprávě).
                                    </li>
                                </ul>
                                <p>Požadované údaje se uvádějí pro každý milník zvlášť.</p>
                            </b-popover>
                            <b-textarea v-model="data.milestones_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Realizacja głównych planowanych osiągnięć projektu zdefiniowanych w umowie o
                                dofinansowaniu z&nbsp;Funduszu Małych Projektów Turów&nbsp;<small
                                    id="popover-3-6-pl">(instrukcja wypełniania&nbsp;<b-icon aria-hidden="true"
                                                                                             icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-6-pl" triggers="hover focus">
                                <p>Obowiązkowa struktura podawanych informacji:</p>
                                <ul>
                                    <li>Nazwa głównego planowanego osiągnięcia</li>
                                    <li>Opis zrealizowanego głównego planowanego osiągnięcia</li>
                                    <li>Osiągnięte mierzalne wskaźniki głównego planowanego osiągnięcia</li>
                                    <li>Wykaz dokumentów potwierdzających zrealizowanie głównego planowanego osiągnięcia
                                        (dokumenty te muszą być dołączone do nin. Raportu)
                                    </li>
                                </ul>
                                <p>Wymagane dane należy podać oddzielnie dla każdego głównego planowanego
                                    osiągnięcia</p>
                            </b-popover>
                            <b-textarea v-model="data.milestones_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                    
                    </div>
                
                </div>
                <div class="col-12">
                    <h3>3. Seznam příloh | Lista załączników </h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Přílohy&nbsp;<small id="popover-3-7-cs">(pokyny k vyplnění&nbsp;<b-icon
                                aria-hidden="true"
                                icon="info-circle"></b-icon>&nbsp;)</small>
                            </label>
                            <b-popover target="popover-3-7-cs" triggers="hover focus">
                                <p>Doklady o provedení povinné propagační činnosti – snímky obrazovky, fotodokumentace,
                                    detailní foto pamětní desky, …</p>
                                <p>Fotodokumentace z postupu realizace projektu (fotografie dokumentující průběh
                                    klíčových prací)</p>
                                <p>Fotodokumentace dosažených výstupů projektu</p>
                                <p>Kopie dokumentů pro potvrzení splnění milníku (kolaudační souhlas, předávací
                                    protokol, zápisy z&nbsp;kontrolních dní, …) Příjemce zvolí dokumenty, které předloží
                                    - ty, které jsou pro daný typ záměru právně nebo zvykově vyžadovány, relevantní dle
                                    charakteru projektu. Dále přiloží případné další dokumenty, na jejichž základě lze
                                    ověřit realizaci milníku.</p>
                                <p>Další přílohy – příjemce vypíše, jaké další dokumenty předkládá.</p>
                            </b-popover>
                            <b-textarea v-model="data.attachments_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>Załączniki&nbsp;<small id="popover-3-7-pl">(instrukcja wypełniania&nbsp;<b-icon
                                aria-hidden="true" icon="info-circle"></b-icon>&nbsp;)</small></label>
                            <b-popover target="popover-3-7-pl" triggers="hover focus">
                                <p>Dokumenty dotyczące obowiązkowych działań promocyjnych - zrzuty ekranu stron
                                    internetowych, dokumentacja fotograficzna, szczegółowe zdjęcie tablicy pamiątkowej,
                                    etc..</p>
                                <p>Dokumentacja fotograficzna z postępów realizacji projektu (zdjęcia dokumentujące
                                    przebieg kluczowych prac)</p>
                                <p>Dokumentacja fotograficzna zrealizowanych produktów projektu</p>
                                <p>Kopie formalnych dokumentów potwierdzających spełnienie głównego planowanego
                                    osiągnięcia (zgoda na użytkowanie, protokół zdawczo-odbiorczy, protokoły z wizyt
                                    kontrolnych, etc.). Beneficjent wybierze dokumenty, które złoży – które są prawnie
                                    lub zwyczajowo wymagane dla danego rodzaju przedsięwzięcia, adekwatne do charakteru
                                    projektu. Ponadto dołącza ew. inne dokumenty, na podstawie których można
                                    zweryfikować zrealizowanie głównego planowanego osiągnięcia.</p>
                                <p>Inne załączniki - beneficjent wymieni, jakie inne dokumenty składa.</p>
                            </b-popover>
                            <b-textarea v-model="data.attachments_pl" max-rows="8" rows="2"></b-textarea>
                        </div>
                    </div>
                </div>
            </b-row>
            
            <div v-if="data.status == ''" class="form-group">
                <b-button variant="danger" @click="deleteItem(data.id)">{{ $t('universalTxt.delete') }}
                    <b-icon icon="trash-fill"></b-icon>
                </b-button>
            </div>
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button class="float-left" form="form1" type="reset" variant="outline-secondary">
                    {{ $t('universalTxt.closeBtn') }}
                </b-button>
                <b-button class="float-right" form="form1" type="submit" variant="primary">
                    {{ $t('universalTxt.saveBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

export default {
    name: "projects-edit",
    components: {},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            data: {},
        }
    },
    methods: {
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {'id': this.id},
                    method: 'GET'
                });
                
                this.data = response.data[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async onSubmit() {
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/update-item.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: this.data,
                    method: 'PUT'
                });
                
                this.$emit('loadData');
                this.$bvModal.hide('modal');
                this.$router.go(-1);
                this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async deleteItem(id) {
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/articles/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {'id': id},
                        method: 'DELETE'
                    });
                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
                
            } catch (error) {
                this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        onReset() {
            this.$emit('loadData');
            this.$router.go(-1);
        }
        
    },
    computed: {
        languages() {
            return this.$store.getters.langs
        },
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();
    },
    created() {
    
    }
}
</script>

<style lang="scss" scoped>

</style>
